import React from 'react';
import { TableContainer, Table, TableHead, TableBody, Paper } from "@mui/material";
import { StyledTableCell, StyledTableRow } from 'src/components/parts/mui/StyledTables';

export interface IColumnConfig<T> {
  headerLabel: string;
  fieldName: string;
  format?: (value: string | number) => string | number;
  render?: (data: T) => React.ReactNode;
}

export interface ITableProps<T extends object> {
  rows: T[];
  columns: IColumnConfig<T>[];
  rowHover?: boolean;
}

const isFieldNameInT= <T extends object,>(row: T, fieldName: unknown): fieldName is keyof T => {
  return (
  Object.keys(row).includes(`${fieldName}`)
)};

const assertFieldNameInT = <T extends object,>(row:T, fieldName: unknown) => {
  if (!isFieldNameInT<T>(row, fieldName)) {
    throw new Error(`Field Name not found inside the row keys of type T`)
  }
  return row[fieldName]
}

export const HHSimpleTable= <T extends object,>({ rows, columns, rowHover = true }: ITableProps<T>) => {
  return (
    <TableContainer component={Paper}>
      <Table stickyHeader aria-label="sticky table">
        <TableHead>
          <StyledTableRow dark={true} style={{ cursor: 'pointer' }}>
            {columns.map((column) => (
              <StyledTableCell
                dark={true}
                style={{ whiteSpace: 'nowrap', fontWeight: 'bold' }}
              >
                {column.headerLabel}
              </StyledTableCell>
            ))}
          </StyledTableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, rowIndex) => (
            <StyledTableRow style={{ cursor: 'pointer' }}
            dark={true} hover={rowHover} role="checkbox" tabIndex={-1} key={rowIndex}>
              {columns.map((column) => {
                const cellValue = assertFieldNameInT<T>(row, column.fieldName);
                const isValueStringOrNumber = typeof cellValue === 'string' || typeof cellValue === 'number'
                return (
                  <StyledTableCell dark={true} style={{ whiteSpace: 'nowrap' }}>
                    {column.render ? column.render(row) : column.format && isValueStringOrNumber ? column.format(cellValue) : cellValue}
                  </StyledTableCell>
                );
              })}
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};