import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  TextField,
} from '@mui/material';

interface IFixFormTextFieldProps {
  disabled?: boolean
  label: string
  onBlurHandler?: (newValue: string) => void
  onChangeHandler: (newValue: string) => void
  required?: boolean
  size?: 'small' | 'medium'
  value: string
}

/**
 * Renders a controlled text input with `onChange` and `onBlur` handlers that convert 
 * the typed string into a `TFixFnArgumentOption` to use as an argument for the 
 * 'fix function'.
 * @param options.label `TextField` label.
 * @param options.onBlurHandler Function that converts the string into desired argument type when input loses focus.
 * @param options.onChangeHandler Function that converts the string into desired argument type when value changes.
 * @param options.required Whether or not user must provide input.
 * @param options.size Either 'small' or 'medium', for MUI.
 * @param options.value Value for the controlled `TextField` component.
 * @returns Configured MUI `TextField` controlled component.
 */
export const FixFormTextField = ({
  disabled,
  label,
  onBlurHandler,
  onChangeHandler,
  required,
  size = 'small',
  value,
}: IFixFormTextFieldProps) => (
  <FormControl>
    <TextField
      disabled={disabled}
      InputProps={{ style: { backgroundColor: 'white' } }}
      label={label}
      onBlur={(e) => onBlurHandler?.(e.target.value)}
      onChange={(e) => onChangeHandler(e.target.value)}
      required={required}
      size={size}
      value={value}
    />
  </FormControl>
);
  
interface IFixFormCheckboxProps {
  checked: boolean
  label: string
  onChangeHandler: (newValue: boolean) => void
  size?: 'small' | 'medium'
}

/**
 * Renders a controlled `Checkbox` component.
 * @param options.checked Whether or not the controlled `TextField` component is checked.
 * @param options.label `Checkbox` label that can also be clicked to toggle state.
 * @param options.onChangeHandler Function called when checkbox state is toggled.
 * @param options.size Either 'small' or 'medium', for MUI.
 * @returns Configured MUI `Checkbox` controlled component.
 */
export const FixFormCheckbox = ({
  checked,
  label,
  onChangeHandler,
  size = 'medium',
}: IFixFormCheckboxProps) => (
  <FormGroup>
    <FormControlLabel
      control={
        <Checkbox
          checked={checked}
          onChange={(e) => onChangeHandler(e.target.checked)}
          size={size}
          sx={{ pl: 2 }}
        />
      }
      label={label}
    />
  </FormGroup>
);
  