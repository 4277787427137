import { useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import { Link } from "react-router-dom";
import { IMonthlySummaryBreakdownItem } from '../../../../../redux/features/reporting/IMonthlySummaryData';
import { roundFloatToCents } from "../../../../../app-utils";
import {
  compactArray, distinctOnFields, floatVal, Hash, HashOf, keys,
} from '@jamesgmarks/utilities';
import { DownloadCSV } from '../../../../parts/DownloadCSV';
import { partnerCoinStyle } from './styles';
import { RequirePermission } from '../../../../parts/RequirePermission';
import { Credits } from "@llws/typeorm-entities";

import './MonthlySubscriptionBreakdown.css';
import { Credit } from '../../../../../../../entities/hydra';
import { MonthlySubscriptionBreakdownSearchForm } from './MonthlySubscriptionBreakdownSearchForm';
import { EHydraCreditMethod } from '../../../../../interfaces/EHydraCreditMethod';
import { ECreditAppliedAs } from '../../../../../interfaces/ICreditApplied';
import { ECreditTypes } from '../../../../../interfaces/ICredit';

export const MonthlySubscriptionBreakdown = ({
  subscriptionBreakdown,
  legacyCreditBreakdown,
  hydraCreditBreakdown,
  currencyCode,
}: {
  subscriptionBreakdown: IMonthlySummaryBreakdownItem[],
  legacyCreditBreakdown: Credits[],
  hydraCreditBreakdown: Credit[],
  currencyCode?: string,
}) => {
  const [filters, setFilters] = useState({
    itemName: '',
    invoiceNumber: '',
    hideZeroSubtotal: true,
    hideZeroDiscountedTotal: true,
  } as Hash);
  const [filteredBreakdown, setFilteredBreakdown] = useState(subscriptionBreakdown);
  // const [searchClientId, setSearchClientId] = useState(null as Nullable<number>);
  const [optionalFields, setOptionalFields] = useState(['GSID', 'Currency'] as string[]);
  const [sortCriteria, setSortCriteria] = useState(['clientName', 'asc']);
  const [
    showTaxesInColumns,
    // setShowTaxesInColumns
  ] = useState(true);

  // const toggleOptionalField = (key: string) => {
  //   const currentlySelected = optionalFields.includes(key);
  //   setOptionalFields([
  //     ...optionalFields.filter(k => k !== key),
  //     ...(!currentlySelected ? [ key ] : []),
  //   ]);
  // }

  const shouldShowOptionalField = (key: string) =>  optionalFields.includes(key);

  // const optionalFieldsList = {
  //   'GSID': 'GSID',
  //   'Commissions': 'Commissions',
  //   'TaxBreakdowns': 'Tax Breakdowns',
  //   'Currency': 'Currency',
  // };

  const [ totals, setTotals ] = useState({
    partnerRevenue: 0,
    lwsRevenue: 0,
    subTotal: 0,
    taxes: 0,
    total: 0,
  });

  const applyFilters = (
    {
      clientName,
      itemName,
      invoiceNumber,
      isPartnerInvoiced,
      hideZeroSubtotal,
      hideZeroDiscountedTotal,
      currencyCode,
    } : {
      currencyCode: string,
      clientName?: string,
      itemName?: string,
      invoiceNumber?: string,
      isPartnerInvoiced?: boolean,
      hideZeroSubtotal?: boolean,
      hideZeroDiscountedTotal?: boolean,
    },
  ) => {
    setFilteredBreakdown(subscriptionBreakdown.filter((s) => {
      return (!clientName || s.clientName.toLowerCase().includes(clientName.toLowerCase()))
      && (!itemName || s.itemName.toLowerCase().includes(itemName.toLowerCase()))
      && (!invoiceNumber || s.invoiceNumbers.includes(invoiceNumber))
      && (!isPartnerInvoiced || s.clientType === 'partner')
      && (!hideZeroSubtotal || s.subTotal !== 0)
      && (!hideZeroDiscountedTotal || s.discountedSubtotal !== 0)
      && (!currencyCode || s.currencyCode === currencyCode);
    }));
    setFilters({
      clientName,
      itemName,
      invoiceNumber,
      isPartnerInvoiced,
      hideZeroSubtotal,
      hideZeroDiscountedTotal,
    });
  };

  if (filters.hideZeroDiscountedTotal && filteredBreakdown.find(f => f.discountedSubtotal === 0)) {
    applyFilters({ currencyCode: currencyCode ?? '', ...filters });
  }

  useEffect(() => {
    const newTotals = filteredBreakdown.reduce((acc, bi) => ({
      partnerRevenue: (acc.partnerRevenue ?? 0) + bi.partnerRevenue,
      lwsRevenue: acc.lwsRevenue + bi.lwsRevenue,
      subTotal: acc.subTotal + bi.subTotal,
      taxes: acc.taxes + bi.taxData.reduce((totalTax, td) => totalTax + td.taxAmount, 0),
      total: acc.total + bi.total,
    }), {
      partnerRevenue: 0,
      lwsRevenue: 0,
      subTotal: 0,
      taxes: 0,
      total: 0,
    });
    setTotals(newTotals);
  }, [ filteredBreakdown ]);

  // const handleFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   e.preventDefault();
  //   setFilters({
  //     ...filters,
  //     [e.target.name]: e.target.value,
  //   });
  // };

  // const setClientNameFilter = (clientNameFilter: Nullable<string>) => {
  //   // console.log('client name filter = ', clientNameFilter);
  //   setFilters({
  //     ...filters,
  //     clientName: clientNameFilter,
  //   });
  // }

  const getCreditLabel = (credit: Credits) => (
    `Credit${credit?.partner?.name?`: ${credit?.partner?.name}`: ''} #${credit.creditNumber}`
  );
  const getHydraCreditType = (credit: Credit) => {
    const standaloneCreditAppliedTypesMap = {
      [ECreditAppliedAs.payment]: ECreditTypes.payment,
      [ECreditAppliedAs.reduction]: ECreditTypes.reduction,
    };

    return credit.creditMethod === EHydraCreditMethod.creditNote
      ? ECreditTypes.note
      : standaloneCreditAppliedTypesMap[credit.creditsApplied[0].appliedAs];
  };

  const getHydraCreditLabel = (credit: Credit) =>
    `${getHydraCreditType(credit)}\
${credit?.partner?.partnerName ? `: ${credit?.partner?.partnerName}` : ''} #${credit.creditNumber}`;

  const getItemIsPartnerInvoiced = (item: IMonthlySummaryBreakdownItem) => {
    return item.clientType === 'partner';
  };

  const getItemSubtotal = (item: IMonthlySummaryBreakdownItem) => {
    return getItemIsPartnerInvoiced(item) ? item.lwsRevenue : item.subTotal;
  };

  const getItemPartnerRevenue = (item: IMonthlySummaryBreakdownItem) => {
    const partnerCut = (
      !Number.isNaN(item.partnerRevenue / (item.partnerRevenue + item.lwsRevenue))
        ? (item.partnerRevenue / (item.partnerRevenue + item.lwsRevenue))
        : 0
    );
    return partnerCut;
  };

  const getItemTaxTotal = (item: IMonthlySummaryBreakdownItem) => {
    const taxAmount = (
      getItemIsPartnerInvoiced(item)
        ? item.taxData
          .map(td => ((td.subtotal - (td.subtotal * getItemPartnerRevenue(item))) * td.taxRate))
          .reduce((tot, val) => tot + val, 0)
        : item.taxData.map(td => (td.taxAmount)).reduce((tot, val) => tot + val, 0)
    );
    return taxAmount;
  };

  const getItemTotal = (item: IMonthlySummaryBreakdownItem) => {
    const subtotal = getItemSubtotal(item);
    const itemTotal = subtotal - (item.discountPercentage * subtotal) + getItemTaxTotal(item);
    return itemTotal;
  };
  const getItemCommissionTotal = (item: IMonthlySummaryBreakdownItem) => {
    const itemCommissionTotal = item.commissionData.reduce((acc, curr) => acc + curr.commissionAmount, 0);
    return itemCommissionTotal;
  };

  const getItemLwsRevenue = (item: IMonthlySummaryBreakdownItem) => {
    return (getItemPartnerRevenue(item) - getItemCommissionTotal(item));
  };

  const sortFns = {
    'clientName': (a: IMonthlySummaryBreakdownItem, b: IMonthlySummaryBreakdownItem) => (
      (a.clientName.trim().toLowerCase() ?? '') > (b.clientName.trim().toLowerCase() ?? '') ? 1 : -1
    ),
    'itemName': (a: IMonthlySummaryBreakdownItem, b: IMonthlySummaryBreakdownItem) => (
      (a.itemName.trim().toLowerCase() ?? '') > (b.itemName.trim().toLowerCase() ?? '') ? 1 : -1
    ),
    'GSID': (a, b) => (a.generatedSubscriptionId > b.generatedSubscriptionId ? -1 : 1),
    'partnerShare': (a, b) => (getItemPartnerRevenue(a) > getItemPartnerRevenue(b) ? -1 : 1),
    'commissions': (a, b) => (getItemCommissionTotal(a) > getItemCommissionTotal(b) ? -1 : 1),
    'lwsShare': (a, b) => (getItemLwsRevenue(a) > getItemLwsRevenue(b) ? -1 : 1),
    'subtotal': (a, b) => (getItemSubtotal(a) > getItemSubtotal(b) ? -1 : 1),
    'taxTotal': (a, b) => (getItemTaxTotal(a) > getItemTaxTotal(b) ? -1 : 1),
    'total': (a, b) => (getItemTotal(a) > getItemTotal(b) ? -1 : 1),
    'currency': (a, b) => (a.currencyCode > b.currencyCode ? -1 : 1),
  } as HashOf<(a: IMonthlySummaryBreakdownItem, b: IMonthlySummaryBreakdownItem) => number>;

  const sortBy = (criteria: string) => {
    setSortCriteria([criteria, (sortCriteria[0] === criteria && sortCriteria[1] === 'asc') ? 'desc' : 'asc']);
  };
  const getSortIcon = (criteria: string) => {
    return sortCriteria[0] === criteria ? (sortCriteria[1] === 'asc' ? <>&#x25bc;</> : <>&#x25b2;</>) : null;
  };
  const doSort = (a: IMonthlySummaryBreakdownItem, b: IMonthlySummaryBreakdownItem) => {
    return (
      sortFns[sortCriteria[0]](a, b) * (sortCriteria[1] === 'asc' ? 1 : -1)
    );
  };

  const allTaxRates = showTaxesInColumns ? (
    (filteredBreakdown || [])
      .reduce((acc, td) => {
        const itemTaxes = distinctOnFields(
          td.taxData.map(tax => ({ taxName: tax.taxName, taxRate: tax.taxRate })),
          ['taxName', 'taxRate'],
        );
        return [
          ...acc,
          ...compactArray(
            itemTaxes.map(it => !acc.find(a => a.taxRate === it.taxRate && a.taxName === it.taxName) ? it : null),
          ),
        ];
      }, [] as ({ taxName: string, taxRate: number })[])
      .sort((a, b) => a.taxRate < b.taxRate ? -1 : 1)
  ) : [];

  const getTaxRateLabel = ({
    taxName, taxRate,
  } :{
    taxName: string,
    taxRate: number
  }) => `${taxName.slice(0, 1).toUpperCase()}${(taxRate * 100)}`.padStart(2, '0');

  return (<>
    <MonthlySubscriptionBreakdownSearchForm
      currencyCode={currencyCode ?? ''}
      applyFilters={applyFilters}
      applyOptionalFields={setOptionalFields}
    />
    <DownloadCSV filename="breakdown">
      <Table striped bordered hover variant="dark" size="sm">
        <thead>
          <tr>
            <RequirePermission grant="FULL_ACCESS">
              {
                shouldShowOptionalField('GSID')
                && <th onClick={() => sortBy('GSID')} className="sortable-header">GSID {getSortIcon('GSID')}</th>
              }
            </RequirePermission>
            {/* <th>Revenue Stream</th> */}
            <th
              // onClick={() => sortBy('itemName')} className="sortable-header"
            >Invoice(s)</th>
            <th onClick={() => sortBy('itemName')} className="sortable-header">Item {getSortIcon('itemName')}</th>
            <th onClick={() => sortBy('clientName')} className="sortable-header">Client {getSortIcon('clientName')}</th>
            <th onClick={() => sortBy('partnerShare')} className="sortable-header">
              Partner Share {getSortIcon('partnerShare')}
            </th>
            {
              shouldShowOptionalField('Commissions')
              && <th onClick={() => sortBy('commissions')} className="sortable-header">
                Commissions {getSortIcon('commissions')}
              </th>
            }
            <th onClick={() => sortBy('lwsShare')} className="sortable-header">Lws Share {getSortIcon('lwsShare')}</th>
            <th onClick={() => sortBy('subtotal')} className="sortable-header">Subtotal {getSortIcon('subtotal')}</th>
            {/* <th>Discount</th>
            <th>Disc. Total</th> */}
            {
              shouldShowOptionalField('TaxBreakdowns')
              && (
                showTaxesInColumns
                  ? allTaxRates.map(tr => (
                    <th key={getTaxRateLabel(tr)}>{getTaxRateLabel(tr)}</th>
                  ))
                  : <th>Taxes</th>
              )
            }
            <th onClick={() => sortBy('taxTotal')} className="sortable-header">Tax Total {getSortIcon('taxTotal')}</th>
            <th onClick={() => sortBy('total')} className="sortable-header">Total {getSortIcon('total')}</th>
            {
              shouldShowOptionalField('Currency') &&
              <th onClick={() => sortBy('currency')} className="sortable-header">Currency {getSortIcon('currency')}</th>
            }
            <th>Credit Note #</th>
          </tr>
        </thead>
        <tbody>
          {(filteredBreakdown || [])
            .slice()
            .sort((a, b) => doSort(a, b)) // (a.clientName.trim().toLowerCase() ?? '') > (b.clientName.trim().toLowerCase() ?? '') ? 1 : -1)
            .map((ps, i) => {
              const isPartnerInvoiced = ps.clientType === 'partner';
              const subTotal = isPartnerInvoiced ? ps.lwsRevenue : ps.subTotal;
              const partnerCut = (
                !Number.isNaN(ps.partnerRevenue / (ps.partnerRevenue + ps.lwsRevenue))
                  ? (ps.partnerRevenue / (ps.partnerRevenue + ps.lwsRevenue))
                  : 0
              );
              // const clientTotalTaxString = (
              //   // ps.taxLineData
              //   //   ? roundFloatToCents(floatVal(ps.taxLineData), 'up')
              //   //   :
              //     // ps.taxData.map(td => `${td.taxName} (${td.taxRate}): ${roundFloatToCents(td.taxAmount, 'up')}`).join(', ')
              //     ps.taxData
              //     .reduce((acc, td) => {
              //       const label = `${td.taxName} (${td.taxRate})`;
              //       const returnValue = {
              //         ...acc,
              //         [label]: (acc[label] ?? 0) + (td.taxAmount),
              //       };
              //       returnValue.stringData = keys(returnValue).filter(k => k !== 'stringData').map(k => `${k}: ${roundFloatToCents(returnValue[k], 'nearest')}`).join(', ');
              //       return returnValue;
              //       // return `${td.taxName} (${td.taxRate}): ${roundFloatToCents((td.subtotal - (td.subtotal * partnerCut)) * td.taxRate, 'up')}`;
              //     }, {} as Hash).stringData
              // );
              // const taxStringData = (
              //   isPartnerInvoiced
              //     ? (
              //       ps.taxData
              //         .reduce((acc, td) => {
              //           const label = `${td.taxName} (${td.taxRate})`;
              //           const returnValue = {
              //             ...acc,
              //             [label]: (acc[label] ?? 0) + ((td.subtotal - (td.subtotal * partnerCut)) * td.taxRate),
              //           };
              //           returnValue.stringData = keys(returnValue).filter(k => k !== 'stringData').map(k => `${k}: ${roundFloatToCents(returnValue[k], 'nearest')}`).join(', ');
              //           return returnValue;
              //           // return `${td.taxName} (${td.taxRate}): ${roundFloatToCents((td.subtotal - (td.subtotal * partnerCut)) * td.taxRate, 'up')}`;
              //         }, {} as Hash).stringData
              //     )
              //     : clientTotalTaxString
              // );

              const taxTableData = (
                ps.taxData
                  .reduce((acc, td) => {
                    const label = getTaxRateLabel(td); // `${td.taxName} (${td.taxRate})`;
                    const returnValue = {
                      ...acc,
                      [label]: (acc[label] ?? 0) + (
                        isPartnerInvoiced
                          ? ((td.subtotal - (td.subtotal * partnerCut)) * td.taxRate)
                          : (td.taxAmount)
                      ),
                    };
                    return returnValue;
                  }, {} as Hash)
              );

              const taxStringData = (
                keys(taxTableData)
                  .filter(k => k !== 'stringData')
                  .map(k => `${k}: ${roundFloatToCents(taxTableData[k], 'nearest')}`)
                  .join(', ')
              );

              const taxAmount = (
                isPartnerInvoiced
                  ? (
                    ps.taxData
                      .map((td) => ((td.subtotal - (td.subtotal * partnerCut)) * td.taxRate))
                      .reduce((tot, val) => tot + val, 0)
                  )
                  : (
                    ps.taxData
                      .map(td => (td.taxAmount))
                      .reduce((tot, val) => tot + val, 0)
                  )
              );

              const lineTotal = subTotal - (ps.discountPercentage * subTotal) + taxAmount;

              const lineCommissionTotal = ps.commissionData.reduce((acc, curr) => acc + curr.commissionAmount, 0);

              return <tr key={i}>
                <RequirePermission grant="FULL_ACCESS">
                  {shouldShowOptionalField('GSID') && <td>
                    <Link
                      to={`/charges/${ps.generatedSubscriptionId}`}
                      target="_blank"
                    >
                      {ps.generatedSubscriptionId}
                    </Link>
                  </td>}
                </RequirePermission>
                <td>{
                  <ul style={{
                    listStyleType: 'none',
                    margin: '0px',
                    padding: '0px',
                  }}>{ps.invoiceData?.map(invoice => <li key={invoice.id} style={{
                      margin: '0px',
                      padding: '0px',
                    }}>
                      <Link
                        to={`/invoices/${invoice.id}`}
                        target="_blank"
                      >{`${invoice.invoiceNumber ? invoice.invoiceNumber : `PV#${invoice.id}`}`}</Link>
                    </li>)}</ul>
                }</td>
                <td>{ps.itemName}</td>
                <td>
                  {ps.clientName} {/** <br /><em>{ps.itemName}</em> */}
                  {isPartnerInvoiced ? <span style={partnerCoinStyle}>partner invoiced</span> : ''}
                </td>
                <td>{roundFloatToCents(ps.partnerRevenue)}</td>
                {
                  shouldShowOptionalField('Commissions') &&
                <td>{roundFloatToCents(lineCommissionTotal)}</td>
                }
                <td>{roundFloatToCents((ps.lwsRevenue - lineCommissionTotal))}</td>
                {/* <td>{roundFloatToCents(subTotal)}</td>
              <td>{ps.discountPercentage * subTotal}</td> */}
                {/* <td>{roundFloatToCents(subTotal - (ps.discountPercentage * subTotal))}</td> */}
                <td>{roundFloatToCents(ps.discountedSubtotal)}</td>
                {
                  shouldShowOptionalField('TaxBreakdowns')
                && (
                  showTaxesInColumns
                    ? allTaxRates.map(tr => (
                      <td key={tr.taxName + tr.taxRate + `${i}`}>
                        {roundFloatToCents(taxTableData[getTaxRateLabel(tr)] ?? 0)}
                      </td>
                    ))
                    : <td>{taxStringData}</td>
                )
                }
                <td>{roundFloatToCents(taxAmount)}</td>
                <td>{roundFloatToCents(lineTotal, 'up')}</td>
                {
                  shouldShowOptionalField('Currency') &&
                <td>{ps.currencyCode}</td>
                }
                <td />
              </tr>;
            })}
          {
            (legacyCreditBreakdown || [])
              .slice()
              .filter(creditItem => {
                return (
                  !filters.clientName || creditItem.client.name.toLowerCase().includes(filters.clientName.toLowerCase())
                )
                  && (
                    !filters.itemName
                    || getCreditLabel(creditItem).toLowerCase().includes(filters.itemName.toLowerCase())
                  )
                  && (!filters.invoiceNumber || creditItem.creditNumber.includes(filters.invoiceNumber))
                  && (
                    !currencyCode
                    || (creditItem.currencyId === 2 && 'USD' === currencyCode)
                    || (creditItem.currencyId === 1 && 'CAD' === currencyCode)
                  );
              })
              .sort((a, b) => (a.client?.name?.toLowerCase() ?? '') > (b.client?.name?.toLowerCase() ?? '') ? 1 : -1)
              .map((creditItem, i) => {
                const label = getCreditLabel(creditItem);
                const partnerClawback = - floatVal(creditItem.partnerClawback ?? '0');
                const subtotal = - floatVal(creditItem.amount);
                const lwsClawback = subtotal - partnerClawback;
                const taxRate = floatVal(creditItem.taxRate);
                const taxTotal = taxRate * subtotal;
                const total = subtotal + taxTotal;
                return <tr key={i}>
                  <RequirePermission grant="FULL_ACCESS">
                    {shouldShowOptionalField('GSID') && <td>
                      {/* <Link
                        to={`/lift_credit/${creditItem.id}`}
                        target="_blank"
                      >
                        {creditItem.id}
                      </Link> */}
                    </td>}
                  </RequirePermission>
                  <td>
                    <ul style={{
                      listStyleType: 'none',
                      margin: '0px',
                      padding: '0px',
                    }}>{creditItem.creditsHasInvoices.map((cri, i) => (
                        <li style={{
                          listStyleType: 'none',
                          margin: '0px',
                          padding: '0px',
                        }} key={i}>
                          <a
                            href={`https://superlift.theliftsystem.com/invoices/breakdown/${cri.invoice.invoiceNumber}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {cri.invoice.invoiceNumber} (SL)
                          </a>
                        </li>
                      ))}</ul>
                  </td>
                  <td>{label}</td>
                  <td>
                    {creditItem.client.name} {/** <br /><em>{ps.itemName}</em> */}
                  </td>
                  <td>{roundFloatToCents(partnerClawback)}</td>
                  {
                    shouldShowOptionalField('Commissions') &&
                    <td> - </td>
                  }
                  <td>{ roundFloatToCents(lwsClawback)}</td>
                  {/* <td>{roundFloatToCents(subTotal)}</td>
                  <td>{ps.discountPercentage * subTotal}</td> */}
                  {/* <td>{roundFloatToCents(subTotal - (ps.discountPercentage * subTotal))}</td> */}
                  <td>{roundFloatToCents(subtotal)}</td>
                  {
                    shouldShowOptionalField('TaxBreakdowns') &&
                    <td> - </td>
                  }
                  <td>{roundFloatToCents(taxTotal)}</td>
                  <td>{roundFloatToCents(total, 'up')}</td>
                  {
                    shouldShowOptionalField('Currency') &&
                    <td>{creditItem.currencyId === 2 ? 'USD' : 'CAD'}</td>
                  }
                  <td />
                </tr>;
              })
          }
          {
            (hydraCreditBreakdown || [])
              .slice()
              .filter(creditItem => {
                return (
                  !filters.clientName
                  || creditItem.client.clientName.toLowerCase().includes(filters.clientName.toLowerCase())
                )
                  && (
                    !filters.itemName
                    || getHydraCreditLabel(creditItem).toLowerCase().includes(filters.itemName.toLowerCase())
                  )
                  && (!filters.invoiceNumber || creditItem.creditNumber.includes(filters.invoiceNumber))
                  && (!currencyCode || (creditItem.currencyCode === 'USD') || (creditItem.currencyCode === 'CAD'));
              })
              .sort((a, b) => (
                (a.client?.clientName?.toLowerCase() ?? '') > (b.client?.clientName?.toLowerCase() ?? '')
                  ? 1
                  : -1
              ))
              .map((creditItem, i) => {
                const label = getHydraCreditLabel(creditItem);
                const partnerClawback = - floatVal(creditItem.partnerClawback ?? '0');
                const subtotal = - floatVal(creditItem.amount);
                const lwsClawback = subtotal - partnerClawback;
                const taxRate = floatVal(creditItem.taxRate);
                const taxTotal = taxRate * subtotal;
                const total = subtotal + taxTotal;
                return <tr key={i}>
                  <RequirePermission grant="FULL_ACCESS">
                    {shouldShowOptionalField('GSID') && <td>
                      {/* <Link
                        to={`/lift_credit/${creditItem.id}`}
                        target="_blank"
                      >
                        {creditItem.id}
                      </Link> */}
                    </td>}
                  </RequirePermission>
                  <td>
                    <ul style={{
                      listStyleType: 'none',
                      margin: '0px',
                      padding: '0px',
                    }}>{creditItem.creditsApplied.map((ca, i) => (
                        <li style={{
                          listStyleType: 'none',
                          margin: '0px',
                          padding: '0px',
                        }} key={i}>
                          <Link
                            to={`/invoices/${ca.invoice.id}`}
                            target="_blank"
                          >{ca.invoice.invoiceNumber}
                          </Link>
                        </li>
                      ))}</ul>
                  </td>
                  <td>
                    <b>H:</b> <span style={{ color: 'darkgray' }}>{label.split(':')[0]}</span>: {label.split(':')[1]}
                  </td>
                  <td>
                    {creditItem.client.clientName} {/** <br /><em>{ps.itemName}</em> */}
                  </td>
                  <td>{roundFloatToCents(partnerClawback)}</td>
                  {
                    shouldShowOptionalField('Commissions') &&
                    <td> - </td>
                  }
                  <td>{roundFloatToCents(lwsClawback)}</td>
                  {/* <td>{roundFloatToCents(subTotal)}</td>
                  <td>{ps.discountPercentage * subTotal}</td> */}
                  {/* <td>{roundFloatToCents(subTotal - (ps.discountPercentage * subTotal))}</td> */}
                  <td>{roundFloatToCents(subtotal)}</td>
                  {
                    shouldShowOptionalField('TaxBreakdowns') &&
                    <td> - </td>
                  }
                  <td>{roundFloatToCents(taxTotal)}</td>
                  <td>{roundFloatToCents(total, 'up')}</td>
                  {
                    shouldShowOptionalField('Currency') &&
                    <td>{creditItem.currencyCode}</td>
                  }
                  <td>{creditItem.creditNote?.noteNumber}</td>
                </tr>;
              })
          }
        </tbody>
        <RequirePermission grant="FULL_ACCESS">
          <tfoot>
            <tr>
              <th />
              <th />
              <th>{roundFloatToCents(totals.partnerRevenue)}</th>
              <th />
              <th>{roundFloatToCents(totals.lwsRevenue)}</th>
              <th>{roundFloatToCents(totals.subTotal)}</th>
              <th />
              <th>{roundFloatToCents(totals.taxes)}</th>
              <th>{roundFloatToCents(totals.total)}</th>
              <th />
              <th />
            </tr>
          </tfoot>
        </RequirePermission>
      </Table>
    </DownloadCSV>
  </>);
};
