import {
  BillingZoneCityOverrides,
  BillingZones, Subscriptions,
  VariableBasePriceOverrides,
  VariableBasePrices,
} from "@llws/typeorm-entities";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import {
  BILLING_FEATURE_BASE_PRICE,
  BILLING_FEATURE_BASE_PRICE_SCALAR,
  BILLING_FEATURE_BILLING_ZONES,
  BILLING_FEATURE_COST_PER_LEAD_MAX,
  BILLING_FEATURE_CUSTOM_CONFIG,
  BILLING_FEATURE_LIFT_BUILDING_PRICES,
  BILLING_FEATURE_MANAGED_EXTERNALLY,
  BILLING_FEATURE_MIN_DURATION,
  BILLING_FEATURE_PROPERTY_LIST,
  BILLING_FEATURE_SUPERSYNC_URL,
  BILLING_FEATURE_VARIABLE_BASE_PRICE,
  TValidBillingFeature,
  assertValidBillingType,
  billingTypeHasFeature,
  getBillingFeatureMetadata,
} from '@llws/hydra-shared';

import { getOne, getMany } from "src/redux/features/dynamic/actions";
import { useAppSelector } from "src/redux/hooks";
import {
  Box, Grid, InputAdornment, Paper, TextField, Tooltip,
} from "@mui/material";
import { ClientDropdown } from "src/components/parts/ClientDropdown";
import { BaseSubscriptionDropdown } from "src/components/parts/BaseSubscriptionDropdown";
import { HHDatePicker } from "src/components/parts/HHDatePicker";
import { floatVal, intVal } from "@jamesgmarks/utilities";
import { RentsyncBuildings } from "../../Subscriptions/EditorComponents/RentsyncBuildings";
import { IColumnConfig, HHSimpleTable } from "src/components/parts/HHSimpleTable";
import { IVariableBasePrice, getSortedPriceBracketsForSubscription } from '@hydra/internal';

export const SubscriptionEditor = () => {
  const { subscriptionId } = useParams<{ subscriptionId: string }>();
  const storeSubscription = useAppSelector((store) => store.dynamic.data.subscriptions?.single);
  const billingFrequencies = useAppSelector((store) => store.dynamic.data.billing_frequencies?.list);

  const [ subscription, setSubscription ] = useState<Subscriptions | null>(
    storeSubscription as unknown as Subscriptions ?? null,
  );
  const [ billingZonesState, setBillingZoneStates ] = useState<null>(
    null,
  );

  const setSubscriptionProp = useCallback(<T extends keyof Subscriptions>(prop: T, newVal: Subscriptions[T]) => {
    if (!subscription) return;
    setSubscription({ ...subscription, [prop]: newVal });
  }, [ subscription, setSubscription ]);

  useEffect(() => setSubscription(storeSubscription as unknown as Subscriptions), [ storeSubscription ]);

  useEffect(() => {
    getOne('subscriptions', subscriptionId, [
      { path: 'client', alias: 'c' },
      { path: 'variableBasePriceOverrides', alias: 'vbpo'},
      { path: 'billingZoneCityOverrides', alias: 'bzco'},
      { path: 'bzco.ownershipGroup', alias: 'bzcoog'},
      { path: 'bzco.city', alias: 'bzcogc' },
      { path: 'bzcogc.province', alias: 'bzcogp' },
      { path: 'baseSubscription', alias: 'bs' },
      { path: 'bs.variableBasePrices', alias: 'vbp'},
      { path: 'bs.billingZones', alias: 'bz' },
      { path: 'bz.billingZoneCities', alias: 'bzc' },
      { path: 'bzc.city', alias: 'gc' },
      { path: 'gc.province', alias: 'gp' },
      { path: 'bs.service', alias: 'svc' },
      { path: 'bs.partner', alias: 'p' },
      { path: 'subscriptionsHasBuildings', alias: 'shb' },
      { path: 'shb.building', alias: 'shbb' },
      { path: 'parentSubscription', alias: 'ps' },
      { path: 'workInProgressQueues', alias: 'wipqs' },
      { path: 'wipqs.workInProgress', alias: 'wip' },
    ]);
    getMany('billing_frequencies');
  }, [ subscriptionId ]);

  const billingFeatureMetadata = useCallback((billingTypeId: number, feature: TValidBillingFeature, field: string) => (
    (getBillingFeatureMetadata(assertValidBillingType(billingTypeId), feature as any) as any)[field]
  ), []);
  const accountType = useMemo(() => (subscription?.handleBilling ? 'client' : 'partner'), [ subscription ]);

  const billingZonesTableColumnsConfig: IColumnConfig<BillingZones>[]= useMemo(() => (
    [
      {
        headerLabel: 'Billing Zone ID',
        fieldName: 'id',
        format: (value) => `ID: ${value}`,
      },
      {
        headerLabel: 'Base Subscription ID',
        fieldName: 'baseSubscriptionId',
      },
      {
        headerLabel: 'Cities',
        fieldName: 'billingZoneCities',
        render: (data: BillingZones) => (
          <Tooltip
            title={
              data.billingZoneCities.map((bzc) => (
                `${bzc.city.cityName}`
              + `: ${bzc.city.province.provinceName}`
              )).join(', ')
            }
            placement="top-start"
          >
            <div>
              {
                data.billingZoneCities.length
              }
            </div>
          </Tooltip>
        ),
      },
      {
        headerLabel: 'Cost Coefficient',
        fieldName: 'costCoefficient',
      },
      {
        headerLabel: 'Price Cap',
        fieldName: 'priceCap',
        format: (value) => value ?? 'Null',
      },
    ]
  ), []);

  const billingZoneCityOverridesColumnsConfig: IColumnConfig<BillingZoneCityOverrides>[]= useMemo(() => (
    [
      {
        headerLabel: 'Billing Zone City Override ID',
        fieldName: 'id',
        format: (value) => `ID: ${value}`,
      },
      {
        headerLabel: 'Subscription ID',
        fieldName: 'subscriptionId',
      },
      {
        headerLabel: 'Ownership Group Name',
        fieldName: 'ownershipGroup',
        render: (data: BillingZoneCityOverrides) => <>{data.ownershipGroup.name ?? 'N/A'}</>,
      },
      {
        headerLabel: 'City',
        fieldName: 'city',
        render: (data: BillingZoneCityOverrides) => (
          <>{data.city.cityName}, {data.city.province.provinceName}</>
        ),
      },
      {
        headerLabel: 'Override Coefficient',
        fieldName: 'overrideCoefficient',
      },
      {
        headerLabel: 'Price Cap Override',
        fieldName: 'priceCapOverride',
        format: (value) => value ?? 'Null',
      },
    ]
  ), []);

  // const VariablePriceOverridesColumnsConfig: IColumnConfig<VariableBasePriceOverrides>[]= useMemo(() => (
  //   [
  //     {
  //       headerLabel: 'ID',
  //       fieldName: 'id',
  //       format: (value) => `ID: ${value}`,
  //     },
  //     {
  //       headerLabel: 'Base Subscription ID',
  //       fieldName: 'baseSubscriptionId',
  //     },
  //     {
  //       headerLabel: 'Max Value',
  //       fieldName: 'maxValue',
  //     },
  //     {
  //       headerLabel: 'Base Price',
  //       fieldName: 'basePrice',
  //       format: (value) => `$ ${value}`,
  //       render: (data: VariableBasePrices) => (
  //         <TextField
  //           label="Base Price"
  //           type="number"
  //           inputProps={{
  //             step: 0.01,
  //           }}
  //           sx={{background: "white"}}
  //           size="small"
  //           value={`${data.basePrice ?? ''}`}
  //           onChange={(e) => console.log(e.target.value)}
  //           InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment> }}
  //         />
  //       ),
  //     },
  //     {
  //       headerLabel: 'Cost Per Unit',
  //       fieldName: 'costPerUnit',
  //       format: (value) => `$ ${value}`,
  //     },
  //   ]
  // ), []);

  const VariableBasePricesColumnsConfig: IColumnConfig<IVariableBasePrice>[]= useMemo(() => (
    [
      {
        headerLabel: 'ID',
        fieldName: 'id',
        format: (value) => `ID: ${value}`,
      },
      {
        headerLabel: 'Base Subscription ID',
        fieldName: 'baseSubscriptionId',
      },
      {
        headerLabel: 'Max Value',
        fieldName: 'maxValue',
      },
      {
        headerLabel: 'Base Price',
        fieldName: 'basePrice',
        format: (value) => `$ ${value}`,
      },
      {
        headerLabel: 'Cost Per Unit',
        fieldName: 'costPerUnit',
        format: (value) => `$ ${value}`,
      },
    ]
  ), []);

  const subscriptionFeeType = useMemo(
    () => subscription?.billingFrequencyId === 1 ? 'ONE_TIME' : 'RECURRING',
    [ subscription ],
  );

  const wipQueue = useMemo(
    () => subscription?.workInProgressQueues?.[0],
    [ subscription ],
  );

  const billingTypeId = useMemo(() => (subscription?.baseSubscription?.service?.billingTypeId), [ subscription ]);

  if (!billingTypeId || !subscription) {
    return <>Loading...</>;
  }

  if (billingTypeHasFeature(assertValidBillingType(billingTypeId), BILLING_FEATURE_MANAGED_EXTERNALLY)) {
    return <Grid container spacing={2} mt={2} mr={2}  justifyContent="center" alignItems="center">
      <Grid item xs={10}>
        <Paper variant='outlined' sx={{padding: '1rem', backgroundColor: '#e0e0e0'}}>
          <Grid item xs={12} style={{ textAlign: 'center' }}>
            <p> This feature is managed somewhere else</p>
            {
              billingTypeHasFeature(assertValidBillingType(billingTypeId), BILLING_FEATURE_SUPERSYNC_URL)
              && <>

                <a href={billingFeatureMetadata(billingTypeId, BILLING_FEATURE_SUPERSYNC_URL, 'url')}>
                  Go to SuperSync
                </a>
              </>
            }
          </Grid>
        </Paper>
      </Grid>
    </Grid>;
  }

  return <>
    <Grid container spacing={2} mt={2} mr={2}  justifyContent="center" alignItems="center">
      <Grid item xs={10}>
        <Paper variant='outlined' sx={{padding: '1rem', backgroundColor: '#e0e0e0'}}>
          <Grid item xs={12} style={{ textAlign: 'center' }}>
            <strong>Subscription Editor ({billingTypeId})</strong>
          </Grid>

          <Grid container item spacing={2}>
            <Grid item xs={12}>
              <div>Account:</div>
              <div>
                <ClientDropdown
                  clientId={subscription.clientId}
                  onClientChanged={(client) => {
                    if ((client?.id ?? null) !== subscription.clientId) {
                      setSubscriptionProp('clientId', client?.id ?? null);
                    }
                  }}
                />
              </div>
            </Grid>

            <Grid item xs={12}>
              <BaseSubscriptionDropdown
                label='Product'
                baseSubscriptionId={subscription.baseSubscriptionId}
                onBaseSubscriptionChanged={(bs) => {
                  // TODO: Handle null selection.
                  if (bs?.id) { setSubscriptionProp('baseSubscriptionId', bs.id); }
                }}
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                label={'PO Number'}
                type="text"
                sx={{background: "white"}}
                size="small"
                value={subscription.poNumber ?? ''}
                onChange={(e) => setSubscriptionProp('poNumber', e.target.value || null)}
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                label={'Quote ID'}
                type="text"
                sx={{background: "white"}}
                size="small"
                value={subscription.quoteId ?? ''}
                onChange={(e) => setSubscriptionProp('quoteId', e.target.value || null)}
              />
            </Grid>
            <Grid item xs={6}>
              <HHDatePicker
                label="Rate Expiry Date"
                value={subscription.rateExpiryDate}
                onChange={(d: Date | null) => { if (d) { setSubscriptionProp('rateExpiryDate', d); } }}
              />

            </Grid>
            <Grid item xs={6}>
          Quote: <input type="file" />
            </Grid>

            <Grid item xs={6}>
              <TextField
                label="Min Properties for Rate"
                type="number"
                inputProps={{
                  step: 0.01,
                }}
                sx={{background: "white"}}
                size="small"
                value={subscription.minPropertiesForRate ?? ''}
                onChange={(e) => (
                  setSubscriptionProp('minPropertiesForRate', e.target.value ? floatVal(e.target.value) : null)
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Max Properties for Rate"
                type="number"
                inputProps={{
                  step: 0.01,
                }}
                sx={{background: "white"}}
                size="small"
                value={subscription.maxPropertiesForRate ?? ''}
                onChange={(e) => (
                  setSubscriptionProp('maxPropertiesForRate', e.target.value ? floatVal(e.target.value) : null)
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                label="Invoice Description"
                type="text"
                sx={{background: "white"}}
                size="small"
                fullWidth
                value={subscription.invoiceDescription}
                onChange={(e) => setSubscriptionProp('invoiceDescription', e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              {
                billingTypeHasFeature(assertValidBillingType(billingTypeId), BILLING_FEATURE_BASE_PRICE)
        && <TextField
          label={billingFeatureMetadata(billingTypeId, BILLING_FEATURE_BASE_PRICE, 'label') ?? 'Base Price'}
          type="number"
          sx={{background: "white"}}
          size="small"
          value={`${subscription.basePrice ?? ''}`}
          onChange={(e) => setSubscriptionProp('basePrice', e.target.value || '0')}
          // disabled={billingFeatureMetadata(billingTypeId, BILLING_FEATURE_BASE_PRICE, 'condition')(subscription)}
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
        />
              }
            </Grid>
            <Grid item xs={12}>
              {
                //TODO: should update the config
                billingTypeHasFeature(assertValidBillingType(billingTypeId), BILLING_FEATURE_BASE_PRICE_SCALAR)
        && <>
          <TextField
            label="Base Price Scalar"
            type="number"
            sx={{background: "white"}}
            inputProps={{
              step: 0.1,
            }}
            size="small"
            value={`${subscription.basePriceScalar ?? ''}`}
            onChange={(e) => setSubscriptionProp('basePrice', e.target.value || '0')}
          />
          <div>
            {/*
            {getBillingFeatureMetadata(assertValidBillingType(billingTypeId), BILLING_FEATURE_BASE_PRICE_SCALAR).labels[0]} {subscription.basePrice},
            {getBillingFeatureMetadata(assertValidBillingType(billingTypeId), BILLING_FEATURE_BASE_PRICE_SCALAR).labels[1]} {subscription.basePrice * subscription.basePriceScalar},
            */}
          </div>
        </>
              }
            </Grid>
            <Grid item xs={12}>
              {
                billingTypeHasFeature(assertValidBillingType(billingTypeId), BILLING_FEATURE_BILLING_ZONES)
        && subscription.billingZoneCityOverrides.length
                  ? <>
                    <label htmlFor="building-search">
                      <h4>Billing Zone City Override</h4>
                    </label>
                    <HHSimpleTable<BillingZoneCityOverrides>
                      rows={subscription.billingZoneCityOverrides}
                      columns={billingZoneCityOverridesColumnsConfig}
                      rowHover={false}
                    />
                  </>
                  : billingTypeHasFeature(assertValidBillingType(billingTypeId), BILLING_FEATURE_BILLING_ZONES)
        && !subscription.billingZoneCityOverrides.length
        && subscription.baseSubscription.billingZones
        && <>
          <label htmlFor="building-search">
            <h4>Billing Zones</h4>
          </label>
          <HHSimpleTable<BillingZones>
            rows={subscription.baseSubscription.billingZones}
            columns={billingZonesTableColumnsConfig}
            rowHover={false}
          />
        </>
              }
            </Grid>
            <Grid item xs={12}>
              {
                billingTypeHasFeature(assertValidBillingType(billingTypeId), BILLING_FEATURE_COST_PER_LEAD_MAX)
                && <>
                  <TextField
                    label="Cost Per Lead Max"
                    type="number"
                    inputProps={{
                      step: 0.01,
                    }}
                    sx={{background: "white"}}
                    size="small"
                    value={`${subscription.costPerLeadMax ?? ''}`}
                    onChange={(e) => setSubscriptionProp('costPerLeadMax', e.target.value || '0')}
                    InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment> }}
                  />
                </>
              }
            </Grid>
            <Grid item xs={12}>
              {
                billingTypeHasFeature(assertValidBillingType(billingTypeId), BILLING_FEATURE_VARIABLE_BASE_PRICE)
                && subscription.variableBasePriceOverrides?.length
                  ? <>
                    {/* <HHSimpleTable<VariableBasePriceOverrides>
                      rows={subscription.variableBasePriceOverrides}
                      columns={VariablePriceOverridesColumnsConfig}
                      rowHover={false}
                    /> */}
                  </>
                  : billingTypeHasFeature(assertValidBillingType(billingTypeId), BILLING_FEATURE_VARIABLE_BASE_PRICE)
                    && <>
                      <label htmlFor="variable base price">
                        <h4>Variable Base Prices</h4>
                      </label>
                      <HHSimpleTable<IVariableBasePrice>
                        rows={getSortedPriceBracketsForSubscription(subscription)}
                        columns={VariableBasePricesColumnsConfig}
                        rowHover={false}
                      />
                    </>

              }
            </Grid>
            <Grid item xs={12}>
              {
                billingTypeHasFeature(assertValidBillingType(billingTypeId), BILLING_FEATURE_MIN_DURATION)
                    && <>
                      <TextField
                        label="Min Duration"
                        type="number"
                        inputProps={{
                          step: 0.01,
                        }}
                        sx={{background: "white"}}
                        size="small"
                        value={`${subscription.minDuration ?? ''}`}
                        onChange={(e) => setSubscriptionProp('minDuration', intVal(e.target.value || '0'))}
                        InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment> }}
                      /></>
              }
            </Grid>
            <Grid item xs={12}>
              {
                billingTypeHasFeature(assertValidBillingType(billingTypeId), BILLING_FEATURE_LIFT_BUILDING_PRICES)
        && <>
          <TextField
            label="Min Duration"
            type="number"
            inputProps={{
              step: 0.01,
            }}
            sx={{background: "white"}}
            size="small"
            value={`${subscription.liftSubscriptions.costPerSingleFamilyHome ?? ''}`}
            onChange={(e) => (
              setSubscriptionProp(
                'liftSubscriptions',
                {...subscription.liftSubscriptions, costPerSingleFamilyHome: (e.target.value || '0')},
              )
            )}
            InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment> }}
          />
          <TextField
            label="Min Duration"
            type="number"
            inputProps={{
              step: 0.01,
            }}
            sx={{background: "white"}}
            size="small"
            value={`${subscription.liftSubscriptions.costPerMultiFamilyHome ?? ''}`}
            onChange={(e) => setSubscriptionProp(
              'liftSubscriptions',
              {...subscription.liftSubscriptions, costPerMultiFamilyHome: (e.target.value || '0')},
            )}
            InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment> }}
          />

        </>
              }
            </Grid>
            <Grid item xs={12}>
              {
                billingTypeHasFeature(assertValidBillingType(billingTypeId), BILLING_FEATURE_PROPERTY_LIST)
        && <>
          <RentsyncBuildings subscription={subscription} setSubscriptionProp={setSubscriptionProp} />
        </>
              }
            </Grid>
            <Grid item xs={12}>
              {
                billingTypeHasFeature(assertValidBillingType(billingTypeId), BILLING_FEATURE_CUSTOM_CONFIG)
        && <>
          <p> Custom config will be edited here </p>
        </>
              }
            </Grid>
            <Grid item xs={12}>
              {
                billingTypeHasFeature(assertValidBillingType(billingTypeId), BILLING_FEATURE_VARIABLE_BASE_PRICE)
        && <TextField
          label="Variable Base Price"
          type="text"
          sx={{background: "white"}}
          size="small"
          value={`${subscription.basePrice ?? ''}`}
          onChange={(e) => setSubscriptionProp('basePrice', e.target.value || '0')}
          InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment> }}
        />
              }
            </Grid>

            <pre>
              {JSON.stringify(subscription, null, 2)}
            </pre>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  </>;
};
