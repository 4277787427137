import { Grid } from "@mui/material";
import { useMemo } from "react";

export const CompanyAddress = ({statementType} : {statementType: string}) => {
  const companyAddress = useMemo(() => (
    <span style={{textAlign: 'right'}}>
      Landlord Web Solutions Inc dba Rentsync<br/>
      HST # 806821856 RT0001<br/>
      #4 - 271B Merritt Street<br/>
      St. Catharines, Ontario<br/>
      L2T 1K1<br/>
      Canada
    </span>
  ), []);
  return (
    <Grid container justifyContent="right" alignItems="right" sx={{textAlign: 'right'}}>
      <Grid item xs={12} pb={1} sx={{color: '#2d2255'}}>
        <span style={{fontSize: '20px', fontWeight: 'bold', padding: '4px', border: '2px solid'}}>
          {statementType.toUpperCase()} STATEMENT
        </span>
      </Grid>
      <Grid item>
        {companyAddress}
      </Grid>
    </Grid>
  );
};