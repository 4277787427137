import { Link, Route, Switch } from "react-router-dom";
import { FeesManager } from "./FeesManager";
import { SubscriptionEditor } from "../Products/Subscriptions/Editor";
import { SubscriptionList } from "./SubscriptionList";

export const SubscriptionManagement = () => {
  return (
    <>
      <Switch>
        <Route path="/subscriptions/fees"><FeesManager /></Route>
        <Route path="/subscriptions/list"><SubscriptionList /></Route>
        <Route path="/subscriptions/:subscriptionId"><SubscriptionEditor /></Route>
        <Route path="/subscriptions">
          <header className="Section-header">Subscription Management</header>
          <ul>
            <li><Link to="/subscriptions/fees">Fees</Link></li>
            <li><Link to="/subscriptions/list">Subscription List</Link></li>
          </ul>
        </Route>
      </Switch>
    </>
  );
};
